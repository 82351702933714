import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'components/common/utils/cookies'

import PublicRoute from './PublicRoutes'
import PrivateRoute from './PrivateRoutes'

import Loader from 'components/common/Loader'

import admin from './AdminRoutes'
import manager from './ManagerRoutes'
import affiliate from './AffiliatesRoutes'
import team_lead from './TeamLeadRoutes'

const Login = lazy(() => import('pages/auth/Login'))
const PasswordRecovery = lazy(() => import('pages/auth/PasswordRecovery'))
const Register = lazy(() => import('pages/auth/Register'))
const TermsAndConditions = lazy(() => import('pages/auth/TermsAndConditions'))
const UserAgreement = lazy(() => import('pages/auth/UserAgreement'))
const PrivacyPolicy = lazy(() => import('pages/auth/PrivacyPolicy'))
const NotFoundPage = lazy(() => import('pages/404'))

const UserRoutes = () => {
  const { user_role } = useSelector((state) => state.auth)
  const userRole = Cookies.get(process.env.REACT_APP_USER_ROLE_KEY)

  const access = {
    admin,
    head: admin,
    manager,
    affiliate,
    'team lead': team_lead,
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} key="/login" />
          <Route path="/register" element={<Register />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        <Route element={<PrivateRoute />}>
          {access[userRole?.toLowerCase() || user_role]}
        </Route>
        {/* 
        (userRole || user_role) &&
        <Route element={<PrivateRoute />}>
          {Object.keys(access).map((role) => (
            <Route key={role} path={`/${role}`} element={access[role]()} />
          ))}
        </Route> */}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default UserRoutes
