//use this after submit for send data to back-end
export const filterEmptyObj = (formData) => {
  const filterEmpty = {}

  for (let key in formData) {
    const value = formData[key]

    if (
      value !== undefined &&
      value !== null &&
      value !== ''
      // && ((typeof value === 'object' && value[0]) || typeof value !== 'object')
    )
      filterEmpty[key] = value
  }

  //filter objects into array { value: smvalue, label: label } to [smvalue,...] type
  for (let key in filterEmpty) {
    if (typeof filterEmpty[key] === 'object') {
      if (filterEmpty[key]?.length) {
        filterEmpty[key] = filterEmpty[key].filter(
          (el) => el !== null && el !== undefined
        )
        filterEmpty[key] = filterEmpty[key].map((el) => {
          return el.value ? el.value : el
        })
      } else {
        filterEmpty[key] = filterEmpty[key].value
      }
    }
  }

  return filterEmpty
}

//Write init value for select inputs when edit  (fix coment for more info)
export const findDataByEdit = (data, value, label) => {
  if (!value?.toString()) return

  if (label) {
    if (typeof value === 'object') {
      const res = data?.filter((el) => value.includes(el.label?.toString()))
      return res
    } else {
      const res = data?.filter(
        (el) => el.label?.toString() === value?.toString()
      )[0]
      return res
    }
  }

  if (typeof value === 'object') {
    const res = data?.filter((el) => value.includes(el.value?.toString()))
    return res
  } else {
    const res = data?.filter(
      (el) => el.value?.toString() === value?.toString()
    )[0]
    return res
  }
}

export const errorsCatch = (data, setError) => {
  if (data?.status === 'error') {
    const errorList = data?.error

    for (let key in errorList) {
      setError(key, {
        type: 'manual',
        message: errorList[key],
      })
    }
  }
}
