const Cookies = {
  set: function (name, value, options = {}) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
      value
    )}`

    if (options.expires) {
      const expires =
        options.expires instanceof Date
          ? options.expires.toUTCString()
          : new Date(options.expires).toUTCString()
      cookieString += `;expires=${expires}`
    }
    if (options.path) {
      cookieString += `;path=${options.path}`
    }
    if (options.domain) {
      cookieString += `;domain=${options.domain}`
    }
    if (options.secure) {
      cookieString += ';secure'
    }

    document.cookie = cookieString
  },

  get: function (name) {
    const cookies = document.cookie
      .split('; ')
      .map((cookie) => cookie.split('='))
    for (const [cookieName, cookieValue] of cookies) {
      if (cookieName === encodeURIComponent(name)) {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  },

  remove: function (name, options = {}) {
    this.set(name, '', {
      expires: new Date(0),
      path: options.path,
      domain: options.domain,
      secure: options.secure,
    })
  },

  removeAll: function (options = {}) {
    document.cookie.split('; ').forEach((cookie) => {
      const [name] = cookie.split('=').map((part) => part.trim())
      this.remove(decodeURIComponent(name), options)
    })
  },

  removeAuth: function () {
    const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY
    const USER_ROLE_KEY = process.env.REACT_APP_USER_ROLE_KEY
    const USER_UUID_KEY = process.env.REACT_APP_USER_UUID_KEY
    const USER_TYPE_KEY = process.env.REACT_APP_USER_TYPE_KEY
    const USER_EMAIL_KEY = process.env.REACT_APP_USER_EMAIL_KEY

    this.remove(TOKEN_KEY)
    this.remove(USER_ROLE_KEY)
    this.remove(USER_UUID_KEY)
    this.remove(USER_TYPE_KEY)
    this.remove(USER_EMAIL_KEY)
  },
}

export default Cookies
