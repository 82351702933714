import React from 'react'
import Button from 'components/Shared/Button'
import Text from 'components/Shared/Text'
import { notify } from './utils/helpers'

import info from 'images/icons/info-black.svg'
import coppy from 'images/icons/coppy.svg'
import copybold from 'images/icons/copy-bold.svg'
import eye from 'images/icons/eye.svg'

import styled from 'styled-components'

const Wrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
`

const WrapSyngle = styled.div`
  display: inline-block;
  cursor: pointer;
`

const SyngleWrap = styled.div`
  padding: 8px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const DescrWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const BtnEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: var(--white);

  border: 1px solid var(--bright);
`

const Coppy = ({
  title,
  text,
  infoIcon,
  btnCoppy,
  information,
  btnText = 'Copy',
  syngle,
  iconEye,
  bold,
  testid,
  scroll,
}) => {
  const coppyLink = () => {
    const isDataObj = typeof information === 'object'

    if (!information) notify('Haven`t information for copy')

    if (information) {
      navigator.clipboard
        .writeText(isDataObj ? JSON.stringify(information) : information)
        .then(() => notify('Coppied!', 'success'))
        .catch((err) => notify(err))
    }
  }

  if (syngle) {
    return (
      <WrapSyngle data-testid={testid} onClick={coppyLink}>
        {iconEye ? (
          <SyngleWrap>
            <img src={eye} alt="eye" width="16" height="10" />
          </SyngleWrap>
        ) : bold ? (
          <div style={{ border: 'none', background: 'none' }}>
            <img src={copybold} alt="bold" width="16" height="16" />
          </div>
        ) : (
          <BtnEvent>
            <img src={coppy} alt="coppy" width="20" height="20" />
          </BtnEvent>
        )}
      </WrapSyngle>
    )
  }

  return (
    <Wrap>
      {title && (
        <Title>
          <Text text={title} size="16" weight="700" />
          {infoIcon && <img src={info} alt="info" />}
        </Title>
      )}
      {text && (
        <DescrWrap>
          <Text text={text} scroll={scroll} />
          {btnCoppy && (
            <BtnEvent onClick={coppyLink}>
              <img src={coppy} alt="coppy" width="20" height="20" />
            </BtnEvent>
          )}
        </DescrWrap>
      )}
      {!btnCoppy && (
        <Button
          text={btnText}
          display="flex"
          justify="end"
          handleClick={coppyLink}
        />
      )}
    </Wrap>
  )
}

export default Coppy
