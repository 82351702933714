import { useState } from 'react'

import styled from 'styled-components'

import arrow from 'images/icons/arrow-left.svg'

const Wrap = styled.div``

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-3);
`

const Icon = styled.div`
  width: fit-content;
  cursor: pointer;
  transform: ${({ isRotate }) =>
    isRotate ? 'rotate(90deg)' : 'rotate(-90deg)'};
`

const Text = styled.p`
  max-width: 528px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--secondary-2);
`

const DropdownText = ({ title, text }) => {
  const [isShown, setIsShown] = useState(false)

  return (
    <Wrap>
      <Div>
        <Title>{title}</Title>
        <Icon isRotate={isShown} onClick={() => setIsShown(!isShown)}>
          <img src={arrow} width="10" height="16" alt="arrow" />
        </Icon>
      </Div>
      {isShown && (
        <div className="scroll scroll-shown">
          <Text>{text}</Text>
        </div>
      )}
    </Wrap>
  )
}

export default DropdownText
