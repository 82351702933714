import { generateUUID } from 'components/common/utils/helpers';
import { useState, useRef } from 'react'

import useOutsideClick from 'components/hooks/useOutsideClick'

import ellipsis from 'images/icons/ellipsis.svg'
import arrowImg from 'images/icons/arrow-down-s.svg'

import styled from 'styled-components'

const ChangeWrap = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`

const ChangeMenu = styled.span`
  display: ${({ isMenuShown }) => (isMenuShown ? 'block' : 'none')};

  position: absolute;
  z-index: 10;
  top: -40px;
  right: 12px;

  border: 1px solid var(--bright);
  cursor: pointer;
  box-shadow: 0px 4px #d7dff6;
  background: var(--white);
`

const Container = styled.span`
  min-width: ${({ minwidth }) => (minwidth ? `${minwidth}px` : '100px')};
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 4px 0;
`

const Line = styled.span`
  width: 100%;
  height: 1px;
  background-color: var(--bright);
  margin-top: 4px;
  margin-bottom: 4px;
`

const ChangeBtn = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-3);
  background-color: inherit;
  padding: 4px 8px;

  &:hover {
    color: var(--primary-4);
  }
`

const ArrowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 8px;
`

const Arrow = styled.img`
  transform: rotate(180deg);
`

const Actions = ({ uuid, data, statuses, minWidth, rotate }) => {
  const [isMenuShown, setIsMenuShown] = useState(false)
  const changeWrapRef = useRef(null)

  useOutsideClick(changeWrapRef, () => setIsMenuShown(false))

  return (
    <ChangeWrap
      ref={changeWrapRef}
      onClick={() => setIsMenuShown(statuses && true)}
      className="unclicable"
      data-testid="actions-container"
    >
      <img
        src={ellipsis}
        alt="ellipsis"
        className="unclicable"
        style={{
          transform: `${rotate && 'rotate(90deg)'}`,
        }}
      />
      <ChangeMenu
        isMenuShown={isMenuShown}
        className="unclicable"
        data-testid="actions_menu"
      >
        {statuses && (
          <Container minwidth={minWidth} className="unclicable">
            {statuses.map(({ text, cb, arrow }) =>
              text === 'line' ? (
                <Line key={generateUUID()} />
              ) : (
                <ChangeBtn
                  onClick={() => cb(uuid, text, data)}
                  key={generateUUID()}
                  className="unclicable"
                  data-testid={`btn_action_${text}`}
                >
                  <ArrowWrap className="unclicable">
                    <div>{text}</div>
                    {arrow && (
                      <Arrow
                        src={arrowImg}
                        alt="arrow"
                        className="unclicable"
                      />
                    )}
                  </ArrowWrap>
                </ChangeBtn>
              )
            )}
          </Container>
        )}
      </ChangeMenu>
    </ChangeWrap>
  )
}

export default Actions
