import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useMemo } from 'react'
import { notificationsActions } from 'redux/notificationsSlice'

const allActions = {
  ...notificationsActions,
}

export const useActions = () => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch])
}
