import { generateUUID } from 'components/common/utils/helpers';
import { useState } from 'react'

import styled from 'styled-components'

const Wrap = styled.div`
  width: fit-content;
  margin: ${({ center }) => center && '0 auto'};
`

const Btn = styled.button`
  position: relative;
  width: 76px;
  height: 24px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #2f455c;
  background: ${({ active }) => (active ? '#34F5C5' : 'var(--white)')};

  :not(:last-child) {
    :after {
      content: '';
      display: ${({ active }) => active && 'none'};
      position: absolute;
      width: 1px;
      height: 16px;
      right: 0;
      top: 3px;

      background: var(--bright);
    }
  }
`

const ActionTabs = ({ data, center, handleChange, initialValue }) => {
  const [isActive, setisActive] = useState(initialValue ?? data[0])

  const handleClick = (value) => {
    setisActive(value)
    handleChange(value)
  }

  return (
    <Wrap center={center}>
      {data?.map((el) => (
        <Btn
          active={el === isActive}
          onClick={() => handleClick(el)}
          key={generateUUID()}
        >
          {el}
        </Btn>
      ))}
    </Wrap>
  )
}

export default ActionTabs
