import styled from 'styled-components'

const Swiper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background-color: #e7eadd;
    animation: swiper 2s ease infinite;
    z-index: 5;
  }

  @keyframes swiper {
    0% {
      width: 0%;
      left: 0;
    }
    30% {
      width: 100%;
    }
    50% {
      right: 0;
    }
    70% {
      width: 100%;
    }
    100% {
      width: 0%;
      right: 0;
    }
  }
`

const Text = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: var(--secondary-1);
  text-transform: uppercase;
`

const LoaderSwiper = () => {
  return (
    <Swiper data-testid="loader-swiper">
      <Text>Loading...</Text>
    </Swiper>
  )
}

export default LoaderSwiper
