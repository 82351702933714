import styled from 'styled-components'

import iconInfo from 'images/icons/info-black.svg'
import switchFalse from 'images/icons/switch-false.svg'
import switchTrue from 'images/icons/switch-true.svg'

const Wrap = styled.div`
  width: ${({ width }) => width};

  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : 'start')};
  flex-direction: ${({ reverse }) => reverse && 'row-reverse'};
  gap: 12px;

  cursor: pointer;
`

const Text = styled.p`
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
`

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`

const ConfirmationToggle = ({
  text,
  infoIcon,
  callbackFun,
  value = false,
  reverse,
  slice,
  justify,
  width,
}) => {
  const handleChange = () => {
    if (callbackFun) callbackFun(!value)
  }

  return (
    <Wrap
      onClick={handleChange}
      reverse={reverse}
      justify={justify}
      width={width}
    >
      <Row>
        {slice ? <Text>{text?.slice(0, 7)}</Text> : <Text>{text}</Text>}
        {infoIcon && <img src={iconInfo} width="10" height="15" alt="info" />}
      </Row>
      <ToggleWrap>
        {value ? (
          <img src={switchTrue} width="36" height="20" alt="switch false" />
        ) : (
          <img src={switchFalse} width="36" height="20" alt="switch false" />
        )}
      </ToggleWrap>
    </Wrap>
  )
}

export default ConfirmationToggle
