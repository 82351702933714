import infoIcom from 'images/icons/info-big.svg'

import styled from 'styled-components'

const Wrap = styled.div`
  display: inline-block;
  padding: 5px 13px;
  border: 1px solid var(--bright);

  background: var(--white);
  cursor: pointer;
`

const Img = styled.img``

const Info = () => {
  return (
    <Wrap>
      <Img src={infoIcom} width="7" height="19" alt="info" />
    </Wrap>
  )
}

export default Info
