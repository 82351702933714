import { createPortal } from 'react-dom'

import Button from 'components/Shared/Button'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

import crossIcon from 'images/icons/cross.svg'

const Overflow = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.1);
`

const Form = styled.form`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  max-height: 80vh;
  padding: 16px;

  border: 1px solid var(--bright);
  background-color: var(--white);
  overflow: ${({ overflow }) => overflow};
`

const SympleWrap = styled.div`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  max-height: ${({ maxheight }) => maxheight};
  padding: ${({ padding }) => padding || '16px'};

  overflow: ${({ overflow }) => overflow};
  border: 1px solid var(--bright);
  background-color: var(--white);
`

const FilterModal = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  height: 96vh;
  display: flex;
  flex-direction: column;

  overflow: scroll;
  width: ${({ width }) => width && `${width}px`};
  padding: 16px;

  border: 1px solid var(--bright);
  background-color: var(--white);

  @media ${device.mobile} {
    width: 96%;
    right: 8px;
    top: 8px;
    height: 80vh;
  }
`

const Title = styled.h2`
  color: var(--primary-3);
  text-transform: uppercase;
  margin-bottom: 16px;
`

const ModalBtns = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  margin-top: ${({ mt }) => (mt ? mt : '54px')};
`

const CustomBtn = styled.div`
  margin-right: auto;
`

const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CrossWrap = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;
`

const Modal = ({
  children,
  title,
  handleSubmit,
  width = '650',
  height,
  btn,
  handleClose,
  type,
  mt,
  overflow,
  scroll,
  btnsOff,
  padding,
}) => {
  const closeModal = (e) => {
    if (e.currentTarget === e.target) handleClose(false)
  }

  if (type === 'symple') {
    return createPortal(
      <Overflow onClick={closeModal}>
        <SympleWrap width={width} height={height} padding={padding}>
          {title && <Title>{title}</Title>}
          <InputsWrap>{children}</InputsWrap>
          {!btnsOff && (
            <ModalBtns mt={mt}>
              {btn && <CustomBtn>{btn}</CustomBtn>}
              <Button text="cancel" handleClick={() => handleClose(false)} />
              <Button
                text="Apply"
                blue
                type="text"
                handleClick={() => {
                  handleSubmit()
                  handleClose(false)
                }}
              />
            </ModalBtns>
          )}
        </SympleWrap>
      </Overflow>,
      document.querySelector('#main-wrap')
    )
  }

  if (type === 'filterNew') {
    return createPortal(
      <Overflow data-testid="modal-overlay" onClick={closeModal}>
        <form onSubmit={handleSubmit}>
          <FilterModal width={width} height={height}>
            {title && <Title>{title}</Title>}
            <CrossWrap onClick={() => handleClose(false)}>
              <img
                src={crossIcon}
                alt="cross"
                width="24"
                height="24"
                data-testid="closeBtn"
              />
            </CrossWrap>
            <InputsWrap>{children}</InputsWrap>
            <ModalBtns mt="auto">
              {btn && <CustomBtn>{btn}</CustomBtn>}
              <Button
                text="Apply"
                blue
                type="submit"
                marginT="32px"
                data-testid="modalApplyBtn"
              />
            </ModalBtns>
          </FilterModal>
        </form>
      </Overflow>,
      document.querySelector('#main-wrap')
    )
  }

  if (type === 'filter') {
    return createPortal(
      <Overflow data-testid="modal-overlay" onClick={closeModal}>
        <FilterModal width={width} height={height}>
          {title && <Title>{title}</Title>}
          <CrossWrap onClick={() => handleClose(false)}>
            <img
              src={crossIcon}
              alt="cross"
              width="24"
              height="24"
              data-testid="closeBtn"
            />
          </CrossWrap>
          <InputsWrap>{children}</InputsWrap>
          <ModalBtns mt="auto">
            {btn && <CustomBtn>{btn}</CustomBtn>}
            <Button
              text="Apply"
              blue
              type="submit"
              marginT="32px"
              handleClick={() => {
                handleSubmit()
                handleClose(false)
              }}
              data-testid="modalApplyBtn"
            />
          </ModalBtns>
        </FilterModal>
      </Overflow>,
      document.querySelector('#main-wrap')
    )
  }

  return createPortal(
    <Overflow onClick={closeModal}>
      <Form
        onSubmit={handleSubmit}
        width={width}
        height={height}
        overflow={overflow}
        className={scroll && 'scroll scroll-shown'}
      >
        {title && <Title>{title}</Title>}
        <InputsWrap>{children}</InputsWrap>
        <ModalBtns mt={mt}>
          {btn && <CustomBtn>{btn}</CustomBtn>}
          <Button text="cancel" handleClick={() => handleClose(false)} />
          <Button data-testid="applyBtn" text="Apply" blue type="submit" />
        </ModalBtns>
      </Form>
    </Overflow>,
    document.querySelector('#main-wrap')
  )
}

export default Modal
