import { useSelector } from 'react-redux'

import { generateUUID } from 'components/common/utils/helpers'

import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Item = styled.li`
  color: var(--primary-3);
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  padding: 2px 6px;

  background: var(--secondary-1, var(--secondary-1));
`

const WrappedList = ({ data, isKeys }) => {
  const { constants } = useSelector((state) => state.info)

  if (Array.isArray(data)) {
    return (
      <List>
        {isKeys
          ? data
              ?.map((el) => constants.goals[el])
              .map((el) => <Item key={generateUUID()}>{el}</Item>)
          : data.map((el) => <Item key={generateUUID()}>{el}</Item>)}
      </List>
    )
  }

  return (
    <List>
      <Item>{data}</Item>
    </List>
  )
}

export default WrappedList
