import { useNavigate } from 'react-router-dom'

import Text from 'components/Shared/Text'

import styled from 'styled-components'
import arrow from 'images/icons/arrow-left.svg'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ width }) => (width ? width : 'fit-content')};
  margin: ${({ margin }) => margin};
  padding-left: ${({ pl }) => pl};
  cursor: pointer;
`

const ArrowWrap = styled.div`
  display: flex;
  align-items: center;
`

const RouterBack = ({
  text = 'back',
  pl,
  width,
  size = '16',
  uppercase = true,
  margin,
}) => {
  const navigate = useNavigate()

  return (
    <Wrap data-testid='backBtn' onClick={() => navigate(-1)} pl={pl} width={width} margin={margin}>
      <ArrowWrap>
        <img src={arrow} alt="arrow" />
      </ArrowWrap>
      <Text text={text} size={size} weight="700" uppercase={uppercase} />
    </Wrap>
  )
}

export default RouterBack
