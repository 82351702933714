import { useState } from 'react'

import { filterEmptyObj } from 'components/common/utils/formHelpers'
import { notify } from './helpers'

const getFormElements = (form) => Array.from(form.elements)

const validateElement = (el) => {
  const isRequired = JSON.parse(el.getAttribute('data-required') || 'false')
  let isEmptyValue
  let value

  if (el.multiple) {
    const selectedValues = Array.from(el.selectedOptions).map(
      (option) => option.value
    )
    value = selectedValues

    isEmptyValue = selectedValues.length === 0
  } else {
    isEmptyValue = !el.value
    value = el.value
  }

  return {
    name: el.name,
    isInvalid: isRequired && isEmptyValue,
    value,
  }
}

const validateFormData = (form) => {
  const elements = getFormElements(form)

  let newFormData = {}

  const elementsObject = elements.reduce((acc, el) => {
    if (el.name) {
      const { name, isInvalid, value } = validateElement(el)
      acc[name] = isInvalid
      newFormData[el.name] = value
    }
    return acc
  }, {})

  const isFormInvalid = Object.values(elementsObject).some((value) => value)

  return {
    formData: newFormData,
    errors: elementsObject,
    isFormInvalid,
  }
}

const useFormValidator = (dafaultValues = {}) => {
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(dafaultValues)
  const [isReset, setIsReset] = useState(false)

  const validate = (e) => {
    e.preventDefault()
    const { errors, isFormInvalid, formData } = validateFormData(e.target)

    setErrors(errors)
    setFormData(formData)

    if (isFormInvalid) {
      notify('Fill in all required fields!', 'error')
    }

    return { isFormInvalid, filteredData: filterEmptyObj(formData) }
  }

  const reset = (newData = {}) => {
    setFormData(newData)

    setIsReset(true)

    setTimeout(() => {
      setIsReset(false)
    }, 500)
  }

  const setValue = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }))

    setIsReset(true)

    setTimeout(() => {
      setIsReset(false)
    }, 500)
  }

  return {
    errors,
    validate,
    formData,
    reset,
    isReset,
    setValue,
  }
}

export default useFormValidator
