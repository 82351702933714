import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  time: '',
  time_zone: '',
  zone_hours: '',
  viewPortSize: '1920',
  viewPortDevise: 'desktop',
  constants: {},
  options: {},
  loading: false,
  error: null,
}

const checkDevice = (size) => {
  if (size < 768) return 'mobile'
  if (size < 1024) return 'tablet'
  if (size < 1440) return 'laptop'
  if (size < 1920) return 'laptopL'
  return 'desktop'
}

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTime: (state, { payload }) => {
      state.time = payload.time
      state.time_zone = payload.time_zone
      state.zone_hours = payload.zone_hours
    },
    viewPortSize: (state, { payload }) => {
      state.viewPortSize = payload
      state.viewPortDevise = checkDevice(payload)
    },
    setConstants: (state, { payload }) => {
      state.constants = payload
    },
    setOptions: (state, { payload }) => {
      state.options = payload
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getTime.pending, (state) => {
  //       state.loading = true
  //       state.error = null
  //     })
  //     .addCase(getTime.fulfilled, (state, { payload }) => {
  //       state.loading = false
  //       state.time = payload
  //     })
  //     .addCase(getTime.rejected, (state, { payload }) => {
  //       state.loading = false
  //       state.error = payload?.data.message
  //     })
})

export const { setLoading, setTime, viewPortSize, setConstants, setOptions } =
  infoSlice.actions

export default infoSlice.reducer
