// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #fff;
  --bright: #ced2d8;
  /* --blue: #194395; */
  --blue: #0186a4;
  --red: #bb4126;
  --green: #00a98a;
  --orange: #ff9900;

  --primary-1: #2f455c;
  --primary-2: #34f5c5;
  --primary-3: #2f455c;
  --primary-4: #0e9cc3;
  --primary-5: #1fcbf2;

  --secondary-1: #ededea;
  --secondary-2: #5f7994;
  --secondary-3: #97a2ac;

  --square-1: #34f5c5;
  --square-2: #1fc8f5;
  --square-3: #005f79;
  --square-4: #003645;
}
`, "",{"version":3,"sources":["webpack://./src/styles/colors.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;;EAEjB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;;EAEpB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;;EAEtB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[":root {\n  --white: #fff;\n  --bright: #ced2d8;\n  /* --blue: #194395; */\n  --blue: #0186a4;\n  --red: #bb4126;\n  --green: #00a98a;\n  --orange: #ff9900;\n\n  --primary-1: #2f455c;\n  --primary-2: #34f5c5;\n  --primary-3: #2f455c;\n  --primary-4: #0e9cc3;\n  --primary-5: #1fcbf2;\n\n  --secondary-1: #ededea;\n  --secondary-2: #5f7994;\n  --secondary-3: #97a2ac;\n\n  --square-1: #34f5c5;\n  --square-2: #1fc8f5;\n  --square-3: #005f79;\n  --square-4: #003645;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
