import { useState } from 'react'

import { generateUUID } from 'components/common/utils/helpers'
import Text from '../Text'

import arrow from 'images/icons/arrow-big.svg'

import styled from 'styled-components'

const Wrap = styled.div``

const Title = styled.p`
  color: var(--primary-3, #0b1c3e);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-transform: capitalize;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  background: #f0f5ff;
  cursor: pointer;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  background-color: #fff;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: ${({ size }) => `calc(100% / ${size} - (8px * (${size} - 1)))`};
`

const P = styled.p`
  display: inline-block;
  color: var(--primary-3, #0b1c3e);

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
`

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
  }
`

const CheckList = ({
  options,
  label,
  callbackFun,
  initialValue = [],
  size = 3,
  filters,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeList, setActiveList] = useState(initialValue)

  const handleChange = (value) => {
    const coppyActives = [...activeList]
    let newActives = []

    if (coppyActives.includes(value)) {
      newActives = coppyActives.filter((el) => el !== value)
      setActiveList(newActives)
    } else {
      newActives = [...coppyActives, value]
      setActiveList(newActives)
    }

    if (callbackFun) callbackFun(newActives)
  }

  if (filters)
    return (
      <Wrap data-testid="checklist_wrap">
        {label && <Title>{label}</Title>}
        <List className="unclosed">
          {options?.map(({ value, label }) => (
            <Item
              onClick={() => handleChange(value)}
              key={generateUUID()}
              size={size}
              className="unclosed"
            >
              <ToggleWrap className="unclosed">
                <input
                  type="checkbox"
                  checked={activeList.includes(value)}
                  onChange={() => false}
                  className="unclosed"
                  name={value}
                  data-testid={`checklist_input-${value}`}
                />
              </ToggleWrap>
              <P className="unclosed">{label}</P>
            </Item>
          ))}
        </List>
      </Wrap>
    )

  const labelText = label
    ? `${label} ${activeList?.length}/${options?.length}`
    : 'Check List'
  return (
    <Wrap>
      <Head onClick={() => setIsOpen(!isOpen)} data-testid="check-wrap">
        <Text text={labelText} size="16" weight="700" />
        <img
          src={arrow}
          alt="arrow"
          width="16"
          height="10"
          style={{ transform: !isOpen && 'rotate(180deg)' }}
        />
      </Head>

      {isOpen && (
        <List data-testid="check-list">
          {options?.map(({ value, label }) => (
            <Item
              onClick={() => handleChange(value)}
              key={generateUUID()}
              size={size}
              data-testid={`check-item-${label}`}
            >
              <ToggleWrap>
                <input
                  type="checkbox"
                  checked={activeList.includes(value)}
                  onChange={() => false}
                />
              </ToggleWrap>
              <P>{label}</P>
            </Item>
          ))}
        </List>
      )}
    </Wrap>
  )
}

export default CheckList
