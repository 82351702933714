/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import styled from 'styled-components'

const Wrap = styled.div`
  .draft-wrapper {
    border: ${({ readOnly }) =>
      readOnly ? 'none' : '1px solid var(--bright)'};

    background: var(--white);
  }

  .draft-editor {
    padding: ${({ readOnly }) => (readOnly ? '0' : '8px')};

    .DraftEditor-editorContainer {
      z-index: 0;
    }
  }

  .rdw-inline-wrapper {
    margin-top: 6px;
  }

  .public-DraftStyleDefault-block {
    margin-top: 0;
  }

  .rdw-editor-toolbar {
    padding: 0;
    border: ${({ readOnly }) => readOnly && 'none'};
    background-color: #f0f5ff;
  }
`

const ControlledEditor = ({ initialValue, handleChange, readOnly }) => {
  const [editorState, setEditorState] = useState(() => {
    let isCorrectJson = false

    if (
      /^[\],:{}\s]*$/.test(
        initialValue
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            ']'
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
      )
    ) {
      isCorrectJson = true
    } else {
      console.error('Невалидная JSON-строка', initialValue)
    }

    if (initialValue && isCorrectJson) {
      const contentState = convertFromRaw(JSON.parse(initialValue))
      return EditorState.createWithContent(contentState)
    } else {
      return EditorState.createEmpty()
    }
  })

  useEffect(() => {
    const contentState = editorState.getCurrentContent()
    const rawContentState = convertToRaw(contentState)
    const value = JSON.stringify(rawContentState)

    if (handleChange) handleChange(value)
  }, [editorState])

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const toolbarConfig = {
    options: ['inline', 'textAlign', 'list'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    textAlign: {
      options: ['left', 'center'],
    },
    list: {
      options: ['ordered'],
    },
    colorPicker: {
      hide: true,
    },
  }

  const empty = {
    options: [],
    inline: {
      options: [],
    },
    textAlign: {
      options: [],
    },
    list: {
      options: [],
    },
    colorPicker: {
      hide: true,
    },
  }

  return (
    <Wrap readOnly={readOnly}>
      <Editor
        editorState={editorState}
        wrapperClassName="draft-wrapper"
        editorClassName="draft-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={readOnly ? empty : toolbarConfig}
        readOnly={readOnly}
      />
    </Wrap>
  )
}

export default ControlledEditor
