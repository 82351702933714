import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotification: (state, { payload }) => {
      const updateData = {
        ...payload,
        id: Date.now(),
      }

      if (state.notifications.length >= 3) {
        state.notifications.pop()
      }

      state.notifications.push(updateData)
    },
    removeNotification: (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== payload
      )
    },
    clearNotifications: (state) => {
      state.notifications = []
    },
  },
})

export const notificationsActions = notificationsSlice.actions
export const { addNotification, clearNotifications } =
  notificationsSlice.actions

export default notificationsSlice.reducer
