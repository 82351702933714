const Loader = () => {
  return (
    <div className="loader" data-testid="loader">
      <div className="square" data-testid="square"></div>
      <div className="square" data-testid="square"></div>
      <div className="square" data-testid="square"></div>
      <div className="square" data-testid="square"></div>
      <div className="square" data-testid="square"></div>
    </div>
  )
}

export default Loader
