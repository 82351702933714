import styled from 'styled-components'

const Wrap = styled.p`
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBot }) => marginBot};

  padding-left: ${({ paddingLeft }) => paddingLeft};

  color: ${({ color }) => (color ? `var(--${color})` : 'var(--primary-3)')};
  opacity: ${({ opacity }) => opacity};

  text-align: ${({ center }) => center && 'center'};
  font-weight: ${({ weight }) => (weight ? weight : '500')};
  font-size: ${({ size }) => (size ? `${size}px` : '13px')};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  line-height: 1.2;
`

const Text = ({
  text,
  color,
  size,
  weight,
  uppercase,
  fontSecond,
  center,
  margin,
  testid,
  opacity,
  marginTop,
  marginBot,
  dangerously,
  scroll,
  ...props
}) => {
  if (dangerously)
    return (
      <Wrap
        color={color}
        size={size}
        weight={weight}
        uppercase={uppercase}
        fontSecond={fontSecond}
        center={center}
        margin={margin}
        data-testid={testid}
        opacity={opacity}
        marginTop={marginTop}
        marginBot={marginBot}
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br>'),
        }}
        {...props}
      />
    )

  return (
    <Wrap
      color={color}
      size={size}
      weight={weight}
      uppercase={uppercase}
      fontSecond={fontSecond}
      center={center}
      margin={margin}
      data-testid={testid}
      opacity={opacity}
      marginTop={marginTop}
      marginBot={marginBot}
      {...props}
      className={scroll && 'scroll'}
    >
      {text}
    </Wrap>
  )
}

export default Text
