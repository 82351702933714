import { useSelector } from 'react-redux'
import Notification from './Notification'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import styled from 'styled-components'

const NotificationsList = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  max-width: 500px;
  z-index: 200;
  width: 100%;
`

const Notifications = () => {
  const { notifications } = useSelector((state) => state.notifications)

  return (
    <NotificationsList>
      <TransitionGroup component={null}>
        {notifications.map(({ type, text, id }) => {
          return (
            <CSSTransition key={id} timeout={500} classNames="notification">
              <Notification key={id} type={type} text={text} id={id} />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </NotificationsList>
  )
}

export default Notifications
