import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Text } from 'components/Shared'
import { BaseInput, Select, Tumbler } from 'components/Shared/Inputs'

import { Modal } from 'components/common'
import useFormValidator from 'components/common/utils/useFormValidator'

import {
  get_setting_postback,
  update_setting_postback,
} from 'api/admin/settings/settingsTest'

import styled from 'styled-components'
import arrowDown from 'images/icons/arrow-new.svg'
import coppy from 'images/icons/copy-new.svg'
import display from 'images/icons/display.svg'
import { device } from 'components/common/constants/device'
import { handleCoppy } from 'components/common/utils/helpers'

const Wrap = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const General = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 30%;
  padding: 16px;

  border: 1px solid #ced2d8;
  background: #fff;

  @media ${device.mobile} {
    width: 100%;
  }
`

const DirectItem = styled.li`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Details = styled.div`
  width: 70%;

  border: 1px solid #ced2d8;
  background: #fff;
  padding: 16px;

  font-size: 14px;

  @media ${device.mobile} {
    width: 100%;
  }
`

const DirectTitle = styled.p`
  margin-bottom: 12px;

  font-weight: 700;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 592px;
  width: 100%;
  min-height: 92px;
  padding: 16px;
  background-color: #ededea;
`

const InfoText = styled.p`
  max-width: 543px;
`

const ArrowDown = styled.img`
  position: absolute;
  left: 284px;
  bottom: -25%;

  width: 12px;
`

const CopyBtn = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const StatusInfo = styled.div`
  max-width: 224px;
  padding: 16px;
  margin-top: 32px;
  background-color: #ededea;
`

const WaitWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 140px;

  @media ${device.mobile} {
    padding-top: 16px;
  }
`

const InfoWrap = styled.div`
  padding: 26px;

  border: 1px solid #ced2d8;
  background-color: #f0f5ff;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const Item = styled.li`
  width: calc(50% - 8px);
`

const TestDirectLink = () => {
  const { options } = useSelector((state) => state.info)

  const [testData, setTestData] = useState(null)

  const [affData, setAffData] = useState()
  const [offerData, setOfferData] = useState()

  useEffect(() => {
    getPostback()
  }, [])

  const updatePostback = async (body) => {
    try {
      const data = await update_setting_postback(body)
      setTestData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getPostback = async (body) => {
    try {
      const data = await get_setting_postback(body)
      if (data?.items) {
        const { affiliates, offers } = data.items

        const affFilterData = affiliates.map((el) => ({
          value: el.uuid,
          label: el.user_name,
        }))
        const offersFilterData = offers.map((el) => ({
          value: el.uuid,
          label: el.title,
        }))

        setAffData(affFilterData)
        setOfferData(offersFilterData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const { errors, validate, formData, isReset } = useFormValidator()

  const handleSubmit = (e) => {
    const { isFormInvalid, filteredData } = validate(e)

    if (isFormInvalid) return

    updatePostback(filteredData)
  }

  //------- Filters -------
  const [modalFilters, setModalFilters] = useState(false)

  const initFilters = {
    Devices: 'false',
    OS: 'false',
  }
  const filtersForm = useFormValidator(initFilters)
  const filterSubmit = (e) => {
    const { isFormInvalid } = filtersForm.validate(e)

    if (isFormInvalid) return
    setModalFilters(false)
  }
  //------------------------------

  return (
    <Wrap>
      <General onSubmit={handleSubmit}>
        <Text text="ADD TEST" weight="700" size="16" />

        <Select
          label="Affiliate"
          placeholder="Select Affiliate"
          name="affiliate_uuid"
          required
          error={errors}
          options={affData}
        />

        <Select
          label="Offer"
          placeholder="Select Offer"
          name="offer_uuid"
          required
          error={errors}
          options={offerData}
        />

        <BaseInput
          name="revenue"
          label="Revenue"
          placeholder="Search Revenue"
          required
          newBase
          errors={errors}
        />

        <Select
          name="goal"
          label="Goals"
          placeholder="Select Goals"
          required
          options={options.goals}
          formData={formData}
          isReset={isReset}
          error={errors}
        />

        <Select
          name="geo"
          label="Geo"
          placeholder="Select Geo"
          required
          options={options.geos}
          formData={formData}
          isReset={isReset}
          error={errors}
        />

        {filtersForm.formData['Devices'] === 'true' && (
          <Select
            name="device"
            label="Devices"
            placeholder="Select Devices"
            options={options.devices}
            formData={formData}
            isReset={isReset}
            error={errors}
            isClearable
          />
        )}

        {filtersForm.formData['OS'] === 'true' && (
          <Select
            name="os"
            label="OS"
            placeholder="Select Os"
            options={options.oss}
            formData={formData}
            isReset={isReset}
            error={errors}
            isClearable
          />
        )}

        <Button
          text="Other fields"
          color="primary-4"
          heightoff
          paddingNone
          display="flex"
          justify="end"
          handleClick={() => setModalFilters(true)}
        />

        <Button
          type="submit"
          text="send test"
          blue
          display="flex"
          justify="end"
          marginT="48px"
          testid="sendBtn"
        />
      </General>

      {modalFilters && (
        <Modal
          title="Other fields"
          width="420"
          handleClose={() => {
            setModalFilters(false)
          }}
          handleSubmit={filterSubmit}
        >
          <List>
            {Object.keys(initFilters).map((el) => (
              <Item>
                <Tumbler
                  text={el}
                  name={el}
                  checked={filtersForm.formData[el] === 'true'}
                  reverse
                />
              </Item>
            ))}
          </List>
        </Modal>
      )}

      <Details>
        {testData ? (
          <div>
            <ul>
              <DirectItem>
                <DirectTitle>Tracking URL</DirectTitle>
                <Info>
                  <InfoText>{testData?.tracking_url}</InfoText>
                  <CopyBtn
                    src={coppy}
                    alt="coppy"
                    onClick={() => {
                      handleCoppy(testData?.tracking_url)
                    }}
                  />
                </Info>
                <ArrowDown src={arrowDown} alt="arrow" />
              </DirectItem>
              <DirectItem>
                <DirectTitle>Redirect Link</DirectTitle>
                <Info>
                  <InfoText>{testData?.redirect_url}</InfoText>
                  <CopyBtn
                    src={coppy}
                    alt="coppy"
                    onClick={() => {
                      handleCoppy(testData?.redirect_url)
                    }}
                  />
                </Info>
                <ArrowDown src={arrowDown} alt="arrow" />
              </DirectItem>
              <DirectItem>
                <DirectTitle>Click ID</DirectTitle>
                <Info>
                  <InfoText>{testData?.click_uuid}</InfoText>
                  <CopyBtn
                    src={coppy}
                    alt="coppy"
                    onClick={() => {
                      handleCoppy(testData?.click_uuid)
                    }}
                  />
                </Info>
                <ArrowDown src={arrowDown} alt="arrow" />
              </DirectItem>
              <DirectItem>
                <DirectTitle>Postback</DirectTitle>
                <Info>
                  <InfoText>{testData?.curl}</InfoText>
                  <CopyBtn
                    src={coppy}
                    alt="coppy"
                    onClick={() => {
                      handleCoppy(testData?.curl)
                    }}
                  />
                </Info>
              </DirectItem>
            </ul>
            <StatusInfo>
              <p>Status:{testData?.status}</p>
            </StatusInfo>
          </div>
        ) : (
          <WaitWrap>
            <img src={display} alt="display" width="84" height="84" />
            <InfoWrap>
              <Text text="To initiate the API test, enter the request data" />
            </InfoWrap>
          </WaitWrap>
        )}
      </Details>
    </Wrap>
  )
}

export default TestDirectLink
