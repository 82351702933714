import styled from 'styled-components'

export default styled.div`
  display: inline-block;
  width: ${(props) => (props.size ? `${props.size}px` : '16px')};
  height: ${(props) => (props.size ? `${props.size}px` : '16px')};

  :after {
    content: ' ';
    display: block;
    width: ${(props) => (props.size ? `${props.size}px` : '16px')};
    height: ${(props) => (props.size ? `${props.size}px` : '16px')};
    border-radius: 50%;
    border: 5px solid var(--white);
    border-color: ${(props) =>
      props.color
        ? `var(--${props.color}) transparent var(--${props.color}) transparent`
        : `var(--black) transparent var(--black) transparent`};
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
