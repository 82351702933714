import { generateUUID } from 'components/common/utils/helpers';
import { useState, useEffect } from 'react'

import { Modal } from 'components/common'

import { Button } from 'components/Shared'
import { ConfirmationToggle } from 'components/Shared/Inputs'

import styled from 'styled-components'

const Wrap = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TimeInput = styled.input`
  position: relative;
  width: 80px;
  height: 36px;

  border: 1px solid #ced2d8;
  color: var(--primary-3, #0b1c3e);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: center;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }
`

const defaultWorkDays = {
  monday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  tuesday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  wednesday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  thursday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  friday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  saturday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
  sunday: { time_from: '00:00', time_to: '00:00', active: 'disabled' },
}

//JSON.parse(JSON.stringify(data)) -->> need for deep coppy(importand!)
const WorkDays = ({ data = defaultWorkDays, handleChange }) => {
  const [newData, setnewData] = useState(JSON.parse(JSON.stringify(data)))
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    setnewData(JSON.parse(JSON.stringify(data)))
  }, [data])

  const keys = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]
  const isAllDaysActive =
    keys.filter((el) => newData[el].active === 'enabled').length === 7

  const handleSubmit = () => {
    handleChange(newData)
  }

  const toggleDay = (value, day) => {
    const a = { ...newData }
    a[day].active = value ? 'enabled' : 'disabled'
    setnewData(a)
  }

  const changeTime = (value, day, type) => {
    const a = { ...newData }
    a[day][type] = value
    setnewData(a)
  }

  const swapWeek = () => {
    const a = { ...newData }

    if (isAllDaysActive) {
      for (let key in a) {
        a[key].active = 'disabled'
      }
    } else {
      for (let key in a) {
        a[key].active = 'enabled'
      }
    }

    setnewData(a)
  }

  return (
    <Wrap>
      <Button text="Workdays" handleClick={() => setIsShowModal(true)} />

      {isShowModal && (
        <Modal
          title="Workdays"
          handleClose={() => {
            setnewData(JSON.parse(JSON.stringify(data)))
            setIsShowModal(false)
          }}
          handleSubmit={handleSubmit}
          width="394"
          type="symple"
          mt="24px"
        >
          <Button
            text={isAllDaysActive ? 'Off all' : 'Full week'}
            color="blue"
            paddingNone
            heightoff
            handleClick={swapWeek}
          />
          {keys?.map((el) => (
            <Item key={generateUUID()}>
              <ConfirmationToggle
                text={el.charAt(0).toUpperCase() + el.slice(1)}
                value={newData[el].active === 'enabled' ? true : false}
                reverse
                callbackFun={(value) => toggleDay(value, el)}
              />
              <div>
                <TimeInput
                  type="time"
                  disabled={newData[el].active === 'disabled'}
                  defaultValue={newData[el]['time_from']}
                  onBlur={(e) => changeTime(e.target.value, el, 'time_from')}
                />
                <span style={{ padding: '0 8px' }}>-</span>
                <TimeInput
                  type="time"
                  disabled={newData[el].active === 'disabled'}
                  defaultValue={newData[el]['time_to']}
                  onBlur={(e) => changeTime(e.target.value, el, 'time_to')}
                />
              </div>
            </Item>
          ))}
        </Modal>
      )}
    </Wrap>
  )
}

export default WorkDays
