import { defaultRequest } from 'api/auth'

const base = 'api/setting/v1/'

export const get_setting_integrations = async () =>
  await defaultRequest(`${base}test-integrations`)

export const update_setting_integrations = async (body) =>
  await defaultRequest(`${base}test-integrations`, 'post', body)

export const get_setting_offer = async () =>
  await defaultRequest(`${base}test-offers`)

export const update_setting_offer = async (body) =>
  await defaultRequest(`${base}test-offers`, 'post', body)

export const get_setting_postback = async () =>
  await defaultRequest(`${base}test-postback-s2s`)

export const update_setting_postback = async (body) =>
  await defaultRequest(`${base}test-postback-s2s`, 'post', body)
