export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobile: 767,
  tablet: 768,
  laptop: 1024,
  laptopS: 1463,
  laptopM: 1776,
  laptopL: 1919,
}
// 1496 -1480
// 1792 -- 1776
export const device = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopS: `(max-width: ${size.laptopS}px)`,
  laptopM: `(max-width: ${size.laptopM}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
}

// export const AVAILABLE_DEVICE = {
//   mobile: 375,
//   tablet: 768,
//   laptop: 1024,
//   laptopS: 1495,
//   laptopM: 1791,
//   laptopL: 1919,
// }

// export const MOBILE = 'mobile'
// export const TABLET = 'tablet'
// export const LAPTOP = 'laptop'
// export const LAPTOPL = 'laptopL'
