import { useEffect } from 'react'

const useOutsideClick = (ref, handleClickOutside) => {
  useEffect(() => {
    if (!handleClickOutside) return

    const handleOutsideClick = (event) => {
      if (!event || !event?.target?.className?.includes || !ref.current) return

      if (event?.target?.className?.includes('unclosed')) return

      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutside()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [ref, handleClickOutside])
}

export default useOutsideClick
