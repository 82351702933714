// import { useState, useRef } from 'react'

// import useOutsideClick from 'components/hooks/useOutsideClick'

// import styled from 'styled-components'

// const Span = styled.span`
//   position: relative;
//   display: inline-block;

//   & .tooltip {
//     display: ${({ trigger }) => trigger === 'click' && 'block'};
//   }

//   &:hover .tooltip {
//     display: block;
//   }

//   &:hover {
//     cursor: ${({ trigger }) => (trigger === 'click' ? 'pointer' : 'default')};
//   }
// `

// const Tooltip = styled.div`
//   width: ${({ ttwidth }) => ttwidth && ttwidth};
//   display: none;
//   position: absolute;
//   top: ${({ tooltipPosition }) => tooltipPosition === 'top' && 'calc(100%)'};
//   bottom: ${({ tooltipPosition }) =>
//     tooltipPosition === 'bottom' && 'calc(110%)'};
//   left: 50%;
//   transform: translateX(-50%);
//   padding: 8px;
//   background-color: #fff;
//   border: 1px solid var(--bright);
//   z-index: 20;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   text-align: ${({ align }) => (align ? align : 'start')};

//   &:before {
//     content: '';
//     display: block;
//     position: absolute;
//     top: ${({ tooltipPosition }) => tooltipPosition === 'top' && '-10px'};
//     bottom: ${({ tooltipPosition }) => tooltipPosition === 'bottom' && '-12px'};
//     transform: ${({ tooltipPosition }) =>
//       tooltipPosition === 'bottom' && 'rotate(180deg)'};
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: transparent transparent var(--bright) transparent;
//   }

//   &:after {
//     content: '';
//     display: block;
//     position: absolute;
//     top: ${({ tooltipPosition }) => tooltipPosition === 'top' && '-9px'};
//     bottom: ${({ tooltipPosition }) => tooltipPosition === 'bottom' && '-10px'};
//     transform: ${({ tooltipPosition }) =>
//       tooltipPosition === 'bottom' && 'rotate(180deg)'};
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: transparent transparent #fff transparent;
//   }
// `

// export default function TooltipComponent({
//   children,
//   data,
//   align,
//   symple = false,
//   ttwidth,
//   trigger = 'hover',
//   tooltipPosition = 'top',
// }) {
//   const [tooltipVisible, setTooltipVisible] = useState(false)
//   const changeWrapRef = useRef(null)

//   useOutsideClick(changeWrapRef, () => setTooltipVisible(false))

//   const handleToggleTooltip = () => {
//     if (trigger === 'click') {
//       setTooltipVisible(!tooltipVisible)
//     }
//   }

//   const handleMouseEnter = () => {
//     if (trigger === 'hover') {
//       setTooltipVisible(true)
//     }
//   }

//   // const handleMouseLeave = () => {
//   //   setTooltipVisible(false)
//   // }

//   return (
//     <Span
//       ref={changeWrapRef}
//       onMouseEnter={handleMouseEnter}
//       // onMouseLeave={handleMouseLeave}
//       onClick={handleToggleTooltip}
//       trigger={trigger}
//       className="unclosed"
//     >
//       {children}
//       {tooltipVisible && data && (
//         <Tooltip
//           className="tooltip unclosed"
//           align={align}
//           ttwidth={ttwidth}
//           tooltipPosition={tooltipPosition}
//         >
//           {symple ? (
//             data
//           ) : typeof data === 'object' ? (
//             <pre>{JSON.stringify(data, null, 2)}</pre>
//           ) : (
//             data
//           )}
//         </Tooltip>
//       )}
//     </Span>
//   )
// }
import { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom' // Импортируем createPortal
import useOutsideClick from 'components/hooks/useOutsideClick'
import styled from 'styled-components'

const Span = styled.span`
  display: inline-block;

  &:hover {
    cursor: ${({ trigger }) => (trigger === 'click' ? 'pointer' : 'default')};
  }
`

const Tooltip = styled.div`
  width: ${({ ttwidth }) => ttwidth && ttwidth};
  display: block;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid var(--bright);
  z-index: 200;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: ${({ align }) => (align ? align : 'start')};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--bright) transparent;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
`

function TooltipPortal({
  children,
  align,
  ttwidth,
  tooltipPosition,
  tooltipRef,
  top,
  left,
}) {
  // Используем createPortal для рендеринга тултипа в body
  return createPortal(
    <Tooltip
      ref={tooltipRef}
      align={align}
      ttwidth={ttwidth}
      tooltipPosition={tooltipPosition}
      top={top} // Позиция сверху
      left={left} // Позиция слева
    >
      {children}
    </Tooltip>,
    document.body // тултип будет рендериться в body
  )
}

export default function TooltipComponent({
  children,
  data,
  align,
  symple = false,
  ttwidth,
  trigger = 'hover',
  tooltipPosition = 'top',
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [tooltipPos, setTooltipPos] = useState({ top: 0, left: 0 })
  const changeWrapRef = useRef(null)
  const tooltipRef = useRef(null)

  useOutsideClick(changeWrapRef, () => setTooltipVisible(false))

  const handleToggleTooltip = (event) => {
    if (trigger === 'click') {
      setTooltipVisible(!tooltipVisible)
      updateTooltipPosition(event)
    }
  }

  const handleMouseEnter = (event) => {
    if (trigger === 'hover') {
      setTooltipVisible(true)
      updateTooltipPosition(event)
    }
  }

  const handleMouseLeave = () => {
    if (trigger === 'hover') {
      setTooltipVisible(false)
    }
  }

  const updateTooltipPosition = (event) => {
    const rect = event.currentTarget.getBoundingClientRect()
    const top = tooltipPosition === 'top' ? rect.top - 10 : rect.bottom + 10
    const left = rect.left + rect.width / 2

    setTooltipPos({ top: event.pageY + 10, left: event.pageX })
  }

  return (
    <Span
      ref={changeWrapRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToggleTooltip}
      trigger={trigger}
      className="unclosed"
    >
      {children}
      {tooltipVisible && data && (
        <TooltipPortal
          align={align}
          ttwidth={ttwidth}
          tooltipPosition={tooltipPosition}
          tooltipRef={tooltipRef}
          top={tooltipPos.top}
          left={tooltipPos.left}
        >
          {symple ? (
            data
          ) : typeof data === 'object' ? (
            <pre>{JSON.stringify(data, null, 2)}</pre>
          ) : (
            data
          )}
        </TooltipPortal>
      )}
    </Span>
  )
}
