import { lazy } from 'react'
import { Route, Navigate } from 'react-router-dom'

const Dashboard = lazy(() => import('pages/team_lead/dashboard'))
const Test = lazy(() => import('pages/admin/FeaturesTest'))

//------- Roles -------
const Roles = lazy(() => import('pages/team_lead/roles'))
const Affiliates = lazy(() => import('components/Admin/Roles/Affiliates'))
const AddAffiliate = lazy(() => import('pages/admin/roles/addAffiliate'))
const Advertisers = lazy(() => import('components/Admin/Roles/Advertisers'))
const AddAdvertiser = lazy(() => import('pages/admin/roles/addAdvertiser'))
const Managers = lazy(() => import('components/Admin/Roles/Managers'))
const AddManagers = lazy(() => import('pages/admin/roles/addManagers'))
//------------------------------

//------- Distribution -------
const Distribution = lazy(() => import('pages/admin/distribution/distribution'))
const ReDistribution = lazy(() =>
  import('components/Admin/ColdApi/ReDistribution')
)
const DistributionList = lazy(() =>
  import('pages/admin/distribution/distributionList')
)
const DistributionRules = lazy(() =>
  import('components/Admin/ColdApi/DistributionRules')
)

const LeadList = lazy(() => import('components/Admin/ColdApi/LeadList'))

const DistributionLeadListDetails = lazy(() =>
  import('pages/admin/distribution/distributionLeadListDetails')
)
const LeadListUserSessions = lazy(() =>
  import('components/Admin/ColdApi/LeadList/Details/UserSession')
)
const LeadListConversion = lazy(() =>
  import('components/Admin/ColdApi/LeadList/Details/Conversion')
)

const DistributionLeadListAdd = lazy(() =>
  import('pages/admin/distribution/distributionLeadListAdd')
)
//------------------------------

//------- Billing -------
const Billing = lazy(() => import('pages/admin/billing/billing'))
const InvoiceCreate = lazy(() => import('pages/admin/billing/invoiceCreate'))
const InvoiceDetail = lazy(() => import('pages/admin/billing/invoiceDetail'))
//------------------------------

//------- Landings -------
const Landings = lazy(() => import('pages/admin/landings'))
const LandingsDetails = lazy(() => import('pages/admin/landings/landingsAdd'))
//------------------------------

//------- Offers -------
const Offers = lazy(() => import('pages/admin/offers'))
const Adds2s = lazy(() => import('pages/admin/offers/adds2s'))
const Addapi = lazy(() => import('pages/admin/offers/addapi'))
const S2sDetail = lazy(() => import('pages/admin/offers/s2sDetail'))
const ApiDetail = lazy(() => import('pages/admin/offers/apiDetail'))
//------------------------------

//------- Statistic -------
const Statistic = lazy(() => import('pages/admin/statistic'))
const StatisticDetails = lazy(() => import('pages/admin/statisticDetails'))
//------- Api -------
const GeneralApi = lazy(() =>
  import('components/Admin/Statistic/Api/Genaral/General')
)
const СlicksApi = lazy(() =>
  import('components/Admin/Statistic/Api/Сlicks/Сlicks')
)
const UserSessionApi = lazy(() =>
  import('components/Admin/Statistic/Api/UserSession/UserSession')
)
const ConversionApi = lazy(() =>
  import('components/Admin/Statistic/Api/Conversion/Conversion')
)
//------------------------------
//------- S2s -------
const GeneralS2s = lazy(() =>
  import('components/Admin/Statistic/S2s/Genaral/General')
)
const СlicksS2s = lazy(() =>
  import('components/Admin/Statistic/S2s/Сlicks/Сlicks')
)
const ConversionS2s = lazy(() =>
  import('components/Admin/Statistic/S2s/Conversion/Conversion')
)
//------------------------------

//------- Settings -------
const Settings = lazy(() => import('pages/admin/settings/settings'))
const SettingsGeneral = lazy(() => import('components/Admin/Settings/General'))
const Domains = lazy(() => import('components/Admin/Settings/Domains'))
const Smtp = lazy(() => import('components/Admin/Settings/Smtp'))
const Postback = lazy(() => import('components/Admin/Settings/Postback'))
const SettingsAffiliates = lazy(() =>
  import('components/Admin/Settings/Affiliates')
)
const Registration = lazy(() =>
  import('components/Admin/Settings/Registration')
)
const News = lazy(() => import('components/Admin/Settings/News'))
const NewsAdd = lazy(() => import('components/Admin/Settings/NewsAdd'))
const Personal = lazy(() =>
  import('components/Admin/Settings/Personal/Personal')
)
const TestApi = lazy(() => import('components/Admin/Settings/TestApi'))
const TestIntegrations = lazy(() =>
  import('components/Admin/Settings/TestApi/TestIntegrations')
)
const TestOffer = lazy(() =>
  import('components/Admin/Settings/TestApi/TestOffer')
)
const PersonalLogs = lazy(() => import('pages/admin/settings/logs'))
//------------------------------

const TeamLeadRoutes = (
  <>
    <Route path="/dashboard" element={<Dashboard />} key="/dashboard" />
    <Route path="/test" element={<Test />} key="/test" />

    <Route path="/distribution" element={<Distribution />}>
      <Route index element={<Navigate to="/distribution/re-distribution" />} />
      <Route path="re-distribution" element={<ReDistribution />} />
      <Route path="rules" element={<DistributionRules />} />
      <Route path="lead-list" element={<LeadList />} />
    </Route>
    <Route path="/distribution/list/:id" element={<DistributionList />} />
    <Route
      path="/distribution/lead-list/details"
      element={<DistributionLeadListDetails />}
    >
      <Route
        index
        element={
          <Navigate to="/distribution/lead-list/details/user-session/:id" />
        }
      />
      <Route path="user-session/:id" element={<LeadListUserSessions />} />
      <Route path="conversion/:id" element={<LeadListConversion />} />
    </Route>

    <Route
      path="/distribution/lead-list/add"
      element={<DistributionLeadListAdd />}
    >
      <Route
        index
        element={<Navigate to="/distribution/lead-list/add/user-session/:id" />}
      />
      <Route path="user-session/:id" element={<LeadListUserSessions />} />
      <Route path="conversion/:id" element={<LeadListConversion />} />
    </Route>

    <Route path="/roles" element={<Roles />}>
      {/* <Route
          index
          element={
            <Navigate
              to={`/roles/${
                type === 'advertiser' ? 'advertiser' : 'affiliates'
              }`}
            />
          }
        /> */}
      <Route path="affiliates" element={<Affiliates />} />
      <Route path="advertisers" element={<Advertisers />} />
      <Route path="managers" element={<Managers />} />
    </Route>

    <Route path="/roles/affiliates/create" element={<AddAffiliate />} />
    <Route path="/roles/affiliates/edit/:id" element={<AddAffiliate />} />
    <Route path="/roles/advertiser/create" element={<AddAdvertiser />} />
    <Route path="/roles/advertiser/edit/:id" element={<AddAdvertiser />} />
    <Route path="/roles/manager/create" element={<AddManagers />} />
    <Route path="/roles/manager/edit/:id" element={<AddManagers />} />

    {/* 
    <Route path="/billing" element={<Billing />} key="/billing" />
    <Route
      path="/invoice-create"
      element={<InvoiceCreate />}
      key="/invoice-create"
    />
    <Route
      path="/invoice-edit/:id"
      element={<InvoiceCreate />}
      key="/invoice-edit/:id"
    />
    <Route
      path="/invoice-delete/:id"
      element={<InvoiceCreate />}
      key="/invoice-delete/:id"
    />
    <Route
      path="/invoice-detail/:id"
      element={<InvoiceDetail />}
      key="/invoice-detail"
    />

    <Route path="/landings" element={<Landings />} />
    <Route path="/landings/create" element={<LandingsDetails />} />
    <Route path="/landings/edit/:id" element={<LandingsDetails />} />

    <Route path="/offers" element={<Offers />} key="/offers" />
    <Route
      path="/offers/add-direct-link"
      element={<Adds2s />}
      key="/offers/add-direct-link"
    />
    <Route
      path="/offers/edit-direct-link/:id"
      element={<Adds2s />}
      key="/offers/edit-direct-link/:id"
    />
    <Route path="/offers/add-api" element={<Addapi />} key="/offers/add-api" />
    <Route
      path="/offers/edit-api/:id"
      element={<Addapi />}
      key="/offers/edit-api/:id"
    />
    <Route
      path="/offers/direct-link/detail/:id"
      element={<S2sDetail />}
      key="/offers/direct-link/detail/:id"
    />
    <Route
      path="/offers/api/detail/:id"
      element={<ApiDetail />}
      key="/offers/api/detail/:id"
    />
*/}

    <Route path="/statistic" element={<Statistic />}>
      <Route index element={<Navigate to="/statistic/direct-link/general" />} />
      <Route path="direct-link">
        <Route index element={<Navigate to="general" />} />
        <Route path="general" element={<GeneralS2s />} />
        <Route path="clicks" element={<СlicksS2s />} />
        <Route path="conversion" element={<ConversionS2s />} />
      </Route>
      <Route path="api">
        <Route index element={<Navigate to="general" />} />
        <Route path="general" element={<GeneralApi />} />
        <Route path="clicks" element={<СlicksApi />} />
        <Route path="user-session" element={<UserSessionApi />} />
        <Route path="conversion" element={<ConversionApi />} />
      </Route>
    </Route>
    {/* 
    <Route path="/statistic/details/:id" element={<StatisticDetails />} />
    <Route path="/settings" element={<Settings />}>
      <Route index element={<Navigate to="/settings/general" />} />
      <Route path="general" element={<SettingsGeneral />} />
      <Route path="domains" element={<Domains />} />
      <Route path="smtp" element={<Smtp />} />
      <Route path="postback" element={<Postback />} />
      <Route path="affiliates" element={<SettingsAffiliates />} />
      <Route path="registration" element={<Registration />} />
      <Route path="news" element={<News />} />
      <Route path="news/create" element={<NewsAdd />} />
      <Route path="news/edit/:uuid" element={<NewsAdd />} />
      <Route path="personal" element={<Personal />} />
      <Route path="test-api" element={<TestApi />}>
        <Route
          index
          element={<Navigate to="/settings/test-api/integrations" />}
        />
        <Route path="integrations" element={<TestIntegrations />} />
        <Route path="offer" element={<TestOffer />} />
        <Route path="direct-link" element={<TestDirectLink />} />
      </Route>
    </Route>

    <Route path="/settings/personal/logs" element={<PersonalLogs />} /> */}
  </>
)

export default TeamLeadRoutes
