import { Text } from 'components/Shared'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
  gap: 32px;

  background-color: var(--white);

  @media ${device.mobile} {
    display: none;
  }
`

const Info = styled.div`
  padding: 24px 32px;
  background-color: #f0f5ff;
  border: 1px solid #ced2d8;
  border-radius: 5px;
`

const Detailed = ({ text }) => {
  return (
    <Wrap>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="85"
        viewBox="0 0 85 85"
        fill="none"
      >
        <path
          d="M53.125 38.9583H58.4375V34.5313H63.75V29.2188H58.4375V24.7917H53.125V38.9583ZM21.25 34.5313H49.5833V29.2188H21.25V34.5313ZM26.5625 53.125H31.875V38.9583H26.5625V43.3854H21.25V48.6979H26.5625V53.125ZM35.4167 48.6979H63.75V43.3854H35.4167V48.6979ZM31.875 74.375C30.8715 74.375 30.0298 74.035 29.3498 73.355C28.6698 72.675 28.331 71.8344 28.3333 70.8333V67.2917H14.1667C12.2188 67.2917 10.5506 66.5975 9.16229 65.2092C7.77396 63.8208 7.08097 62.1539 7.08333 60.2083V17.7083C7.08333 15.7604 7.7775 14.0923 9.16583 12.704C10.5542 11.3156 12.2211 10.6226 14.1667 10.625H70.8333C72.7812 10.625 74.4494 11.3192 75.8377 12.7075C77.226 14.0958 77.919 15.7628 77.9167 17.7083V60.2083C77.9167 62.1563 77.2225 63.8244 75.8342 65.2127C74.4458 66.601 72.7789 67.294 70.8333 67.2917H56.6667V70.8333C56.6667 71.8368 56.3267 72.6785 55.6467 73.3585C54.9667 74.0385 54.1261 74.3774 53.125 74.375H31.875ZM14.1667 60.2083H70.8333V17.7083H14.1667V60.2083Z"
          fill="#D5D8DE"
        />
      </svg>

      <Info>
        <Text
          text={`Detailed settings will be available after the creation of the ${text}`}
        />
      </Info>
    </Wrap>
  )
}

export default Detailed
