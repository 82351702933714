import React, { useState } from 'react'
import { DateRangePicker, Stack } from 'rsuite'
import moment from 'moment'

import { formatDateToString } from './utils/formatDate'

import 'styles/datapicker.css'

import styled from 'styled-components'

const Wrap = styled.div`
  .rs-picker-toggle-value {
    opacity: 0;
  }

  .rs-input-group {
    border-color: ${({ disabled }) => disabled && '#ced2d8 !important'};
    background-color: ${({ disabled }) => disabled && '#f0f5ff !important'};

    input {
      background-color: ${({ disabled }) => disabled && '#f0f5ff !important'};
    }
  }

  input {
    opacity: 0;
  }
`
const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-3);
`

const Input = styled.input`
  width: 80%;

  position: absolute;
  top: 6px;
  left: 8px;
  padding: 4px;

  opacity: 1 !important;
  pointer-events: none;
  color: black;
  background-color: inherit;
`

const DataComponent = ({
  handleChange,
  initialValue,
  title,
  placeholder = 'Today',
  disabled,
}) => {
  const isTypeString = typeof initialValue === 'string'
  const startDateFormated = isTypeString
    ? [
        moment(initialValue, 'YYYY.MM.DD').toDate(),
        moment(initialValue, 'YYYY.MM.DD').toDate(),
      ]
    : null
  const [dates, setDates] = useState(startDateFormated)

  const handleRangeChange = (values) => {
    setDates(values)
    handleChange(formatDateToString(values))
  }

  return (
    <Wrap disabled={disabled}>
      <Title>{title}</Title>
      <Stack style={{ position: 'relative' }}>
        <DateRangePicker
          value={dates}
          ranges={[]}
          placeholder={placeholder}
          className="custom-date-range-picker only-date-range"
          editable={false}
          cleanable={false}
          onChange={handleRangeChange}
          showOneCalendar
          oneTap
          shouldDisableDate={disabled}
        />
        <Input
          type="text"
          value={dates ? moment(dates[0]).format('DD.MM.YYYY') : ''}
          placeholder={placeholder}
          readOnly
          className="custom-input-date"
        />
      </Stack>
    </Wrap>
  )
}

export default DataComponent
