import styled from 'styled-components'

const Wrap = styled.div`
  width: 186px;
  height: 24px;
  background: var(--secondary-1);
`

const Progress = styled.div`
  background-color: ${({ width }) =>
    width < 51
      ? '#66c06f'
      : width < 71
      ? '#ABDA27'
      : width < 81
      ? '#DABD27'
      : width < 91
      ? '#DA9227'
      : '#BB4126'};
  width: ${({ width }) => (width > 100 ? '100%' : `${width}%`)};
  height: 100%;
`

const ProgressBar = ({ width = 0 }) => {
  return (
    <Wrap data-testid="progress-wrap">
      <Progress width={width} data-testid="progress-bar" />
    </Wrap>
  )
}

export default ProgressBar
