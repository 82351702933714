import { generateUUID } from 'components/common/utils/helpers';
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

const Container = styled.div`
  width: 100%;
  padding: 25px 16px 20px 8px;
  border: 1px solid var(--bright);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background-color: var(--white);

  @media ${device.mobile} {
    border: none;
    background-color: transparent;
    padding: 0;
    margin-bottom: 32px;
  }
`

const Wrap = styled.ul`
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: relative;
  padding: 0 20px 20px 40px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--secondary-1);
    border-radius: 10px;
  }

  li {
    text-align: center;
  }

  @media ${device.mobile} {
    padding: 0 20px 0 20px;

    &:after {
      bottom: -19px;
      background-color: var(--white);
    }
  }
`

const Item = styled.li`
  position: relative;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: ${({ isActive, done }) =>
    isActive ? 'var(--primary-3)' : done ? '#66C06F' : 'var(--secondary-2)'};

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 20;
    bottom: -21px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background-color: ${({ isActive, done }) =>
      isActive ? 'var(--primary-2)' : done ? '#66C06F' : 'var(--white)'};
    border-radius: 10px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    background-color: var(--secondary-1);
    border-radius: 10px;
  }

  @media ${device.mobile} {
    &:before {
      background-color: ${({ isActive, done }) =>
        isActive
          ? 'var(--primary-2)'
          : done
          ? '#66C06F'
          : 'var(--secondary-3)'};
    }
    &:after {
      background-color: var(--white);
    }
  }
`

const Progress = ({ handleSwitch, initialComponent, data }) => {
  const { viewPortDevise } = useSelector((state) => state.info)
  const isMobile = viewPortDevise === 'mobile'

  return (
    <Container>
      <Wrap data-testid="progress-wrap">
        {data.map(({ name, text }) => (
          <Item
            key={generateUUID()}
            onClick={() => handleSwitch(name)}
            isActive={initialComponent === name}
            data-testid={`page_${name.replace(/\s+/g, '_').toLowerCase()}`}
          >
            {isMobile ? '' : text}
          </Item>
        ))}
      </Wrap>
    </Container>
  )
}

export default Progress
