import { Text } from 'components/Shared'
import { getCountryByType, generateUUID } from 'components/common/utils/helpers'

import mobile from 'images/icons/devices/mobile.svg'
import tablet from 'images/icons/devices/tablet.svg'
import desktop from 'images/icons/devices/desktop.svg'
import ios from 'images/icons/devices/ios.svg'
import macOs from 'images/icons/devices/mac-os.svg'
import windows from 'images/icons/devices/windows.svg'
import android from 'images/icons/devices/android.svg'
import huawei from 'images/icons/devices/huawei.svg'
import otherOS from 'images/icons/devices/other-os.svg'
import UAH from 'images/icons/currency/uah.svg'
import USD from 'images/icons/currency/usd.svg'
import EUR from 'images/icons/currency/eur.svg'

import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  width: ${({ width }) => width && width};
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  width: ${({ width }) => width && width};
`
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const icons = {
  Mobile: mobile,
  Tablet: tablet,
  Desktop: desktop,
  iOS: ios,
  macOS: macOs,
  Windows: windows,
  Android: android,
  Huawei: huawei,
  'Other OS': otherOS,
  UAH: UAH,
  USD: USD,
  EUR: EUR,
}

const IconsList = ({ data, withText, imgOnly }) => {
  if (!data?.length) return

  if (imgOnly) return <img src={icons[data]} alt={data} />

  if (typeof data === 'string')
    return (
      <List>
        <Item>
          {/* <img src={getCountryByType(data, 'devices')} alt={data} /> */}
          <Row>
            <img src={icons[data]} alt={data} />
            {withText && <Text text={data} />}
          </Row>
        </Item>
      </List>
    )

  return (
    <List>
      {data.map((el) => (
        <Item key={generateUUID()}>
          {el === 'No device'
            ? 'No device'
            : el !== 'ALL' && (
                // <img src={getCountryByType(el, 'devices')} alt={el} />
                <img src={icons[el]} alt={el} />
              )}
        </Item>
      ))}
    </List>
  )
}

export default IconsList
