import { createSlice } from '@reduxjs/toolkit'
import { login, logout } from './auth-operations'

import Cookies from 'components/common/utils/cookies'
import { findAllByDisplayValue } from '@testing-library/react'

const initialState = {
  message: '',
  isLogin: false,
  loading: false,
  error: null,
  user_role: '',
  type: null,
  email: null,
  uuid: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRole: (state, { payload }) => {
      state.user_role = payload
    },
    refresh: (state, { payload }) => {
      state.user_role = payload?.user_role?.toLowerCase()
      state.type = payload?.type?.toLowerCase()
      state.email = payload?.email
      state.uuid = payload?.uuid
      state.isLogin = true
    },
    clear: (state) => {
      state.isLogin = false
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false
        state.isLogin = true
        state.user_role = payload?.user_role?.toLowerCase()
        state.type = payload?.type?.toLowerCase()
        state.email = payload?.email
        state.uuid = payload?.uuid
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false
        // state.error = payload?.data.message
      })

    // builder
    //   .addCase(refresh.pending, (state) => {
    //     state.loading = true
    //     state.error = null
    //   })
    //   .addCase(refresh.fulfilled, (state, { payload }) => {
    //     state.loading = false
    //     state.isLogin = true
    //   })
    //   .addCase(refresh.rejected, (state, { payload }) => {
    //     state.loading = false
    //     state.error = payload?.data.message
    //   })

    builder
      .addCase(logout.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false
        state.isLogin = false
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.loading = false
        state.isLogin = false
        state.error = payload?.data.message
      })
  },
})

export const { setRole, refresh, clear } = authSlice.actions

export default authSlice.reducer
