import { getCountryByType, generateUUID } from 'components/common/utils/helpers'
import TooltipComponent from 'components/common/Tooltip'

import { Text } from 'components/Shared'

import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '4px')};
  row-gap: ${({ rowGap }) => rowGap};
  width: ${({ width }) => width && width};
  overflow: scroll;
  max-height: 250px;
`

const TtList = styled(List)`
  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    box-shadow: ${({ lineoff }) => (lineoff ? 'none' : '0px 4px #d7dff6')};
  }
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  width: ${({ width }) => width && width};
`

const GeosList = ({ data, maxShown = 3, gap, rowGap, tooltipPosition }) => {
  if (!data?.length) return

  if (typeof data === 'string')
    return (
      <List>
        <Item>
          <img src={getCountryByType(data)} alt="country" />
          <span>{data}</span>
        </Item>
      </List>
    )

  const isShowTT = data.length > maxShown
  const layoutTT = (
    <TtList width="70px" className="scroll-shown-always">
      {data?.map((el) => (
        <Item key={generateUUID()} width="50px">
          {el !== 'ALL' && <img src={getCountryByType(el)} alt="country" />}
          <span>{el}</span>
        </Item>
      ))}
    </TtList>
  )

  return (
    <TooltipComponent
      data={isShowTT && layoutTT}
      symple={true}
      trigger="click"
      tooltipPosition={tooltipPosition}
    >
      <List gap={gap} rowGap={rowGap}>
        {data?.map((el, i) =>
          i < maxShown ? (
            <Item key={generateUUID()}>
              {el !== 'ALL' && <img src={getCountryByType(el)} alt="country" />}
              <span>{el}</span>
            </Item>
          ) : (
            i === maxShown && (
              <Text
                text={`All (${data.length})`}
                color="blue"
                key={generateUUID()}
              />
            )
          )
        )}
      </List>
    </TooltipComponent>
  )
}

export default GeosList
